import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function SecurityAndCompliance() {
  return (
    <div className="container">
    <div className="fnt-16">
        <h1 className="my-5 py-5 text-center fnt-60">Your Data Is Safe and Secure</h1>
        <h1 className="fnt-400 fnt-32 mb-5">We take security seriously here at Dripfunnel, and we are proud to exceed the industry standard when it comes to protecting your organization.</h1>
        <div className="d-flex flex-row justify-content-between mb-5">
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">Data Centers</div>
                <div>Dripfunnel is hosted at Amazon data centers, running on Amazon Web Services technology. These data centers located in the US, provide physical security 24/7, state­ of­ the­ art fire suppression, redundant utilities, and biometric devices to ensure your data is safe.</div>
            </div>
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">Network Security</div>
                <div>We take several steps to protect your data and prevent eavesdropping between your systems and ours. All network traffic runs over SSL/HTTPS, the most common and trusted communications protocol on the Internet.</div>
            </div>
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">System Security</div>
                <div>We’re relentlessly updating our systems to protect your data. We regularly replace our virtual systems with new, patched ones. We maintain system consistency using a combination of configuration management, up-to-date images, and continuous deployment.</div>
            </div>
        </div>
        <div className="d-flex flex-row justify-content-between mb-5">
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">Security Operations</div>
                <div>If we see something, we react quickly. We’re always looking for potential system interruptions. If we find something out of place, we address the issue to prevent it in the future.</div>
            </div>
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">Restricted Access</div>
                <div>Only people who need access, get access. We limit production-system access to key members of the Dripfunnel engineering team and expressly forbid passwords.</div>
            </div>
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">Application Level Security</div>
                <div>We physically separate the database instances from application servers.</div>
            </div>
        </div>
        <div className="d-flex flex-row justify-content-between mb-5">
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">Account Cancellation</div>
                <div>If we have to part ways, we’ll make sure your data isn’t at risk. To cancel and delete your account, please contact your account manager or our Customer Success team. Canceling your account will disable all access to the Dripfunnel Platform. Upon request, we will delete all data associated with your account.</div>
            </div>
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">DDoS attack protection</div>
                <div>We use a fast, globally distributed and intelligent always-on DDoS protection powered by Cloudflare, Inc.</div>
            </div>
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">Data encryption at rest</div>
                <div>All our user data (including passwords) is encrypted using battled-proofed encryption algorithms in the database by our database provider AWS.</div>
            </div>
        </div>
        <div className="d-flex flex-row justify-content-between mb-5">
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">Disaster recovery</div>
                <div>We back up all our critical assets and regularly attempt to restore the backup to guarantee a fast recovery in case of disaster. All our backups are encrypted.</div>
            </div>
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">Payment processing</div>
                <div>All payment processing is outsourced to Stripe which is certified as a PCI Level 1 Service Provider. We don’t collect any payment information and are therefore not subject to PCI obligations.</div>
            </div>
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">GDPR Compliance</div>
                <div>Dripfunnnel is committed to ensuring ongoing compliance with the General Data Protection Regulation (GDPR). The GDPR extends the reach of the European Union’s data protection laws and establishes many new requirements for organizations that fall under its scope.</div>
            </div>
        </div>
        <div className="d-flex flex-row justify-content-between mb-5">
            <div className="w-30">
                <div className="fnt-700 mb-4 accent-color fw-bold">CCPA Compliance</div>
                <div>The California Consumer Privacy Act (CCPA) went into effect on January 1, 2020. Dripfunnel is committed to ensuring compliance with the CCPA. The CCPA is a state law that provides consumer privacy rights and protections for residents of the state of California.</div>
            </div>
        </div>
    </div>

</div>
  );
}

export default SecurityAndCompliance;
